var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"id":"card-playername"}},[_c('v-card-title',[_c('span',{attrs:{"id":"card-title"}},[_vm._v(" "+_vm._s(_vm.$t('CardRoomPlayerName.title'))+" "),_c('span',{class:{ blur: _vm.streamerMode }},[_vm._v(_vm._s(_vm.roomName))])])]),_c('v-card-subtitle',{ref:"roomUrl",staticClass:"pb-0"},[_c('span',{class:{ blur: _vm.streamerMode }},[_vm._v(_vm._s(_vm.roomUrl)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":_vm.copy}},[_vm._v(" mdi-content-copy ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"id":"inputPlayerName","value":_vm.name,"maxlength":"20","autofocus":"","label":_vm.$t('CardRoomPlayerName.input'),"error":_vm.invalidName},on:{"input":_vm.setPlayerName}})],1)],1),_c('h3',[_vm._v(_vm._s(_vm.$tc('CardRoomPlayerName.players', _vm.players.length)))]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.players),function(name,i){return _c('v-chip',{key:'player' + i,attrs:{"color":"#424242","dark":""}},[_c('v-avatar',{attrs:{"color":[
                                '#E91B0C',
                                '#5ccc00',
                                '#e0ca00',
                                '#FF1F69',
                                '#00b8b8',
                            ][i % 5],"left":""}},[_vm._v(" "+_vm._s(name.slice(0, 2).toUpperCase())+" ")]),_vm._v(" "+_vm._s(name)+" ")],1)}),1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"dark":"","depressed":"","color":"error"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),(_vm.playerNumber === 1)?_c('v-btn',{attrs:{"id":"btnStart","dark":"","depressed":"","color":"#43B581","disabled":_vm.players.length < 2 || !_vm.canNext},on:{"click":_vm.startGame}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }