var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-container",staticStyle:{"height":"400px"}},[_c('GmapMap',{ref:"mapRef",staticClass:"map",attrs:{"center":{ lat: 0, lng: 0 },"options":{
            mapTypeControl: false,
            gestureHandling: 'greedy',
            styles: _vm.$vuetify.theme.dark ? _vm.$vuetify.theme.themes.dark.gmap : _vm.$vuetify.theme.themes.light.gmap,
        },"zoom":0,"map-type-id":"roadmap"}},_vm._l((_vm.item.rounds),function(r,index){return _c('div',{key:index},[_c('GmapMarker',{attrs:{"position":r.position,"label":(index + 1).toString()}})],1)}),0),_c('div',{staticClass:"result-panel"},_vm._l((_vm.item.rounds),function(r,index){return _c('div',{key:index},[_c('p',[_c('b',[_vm._v(" "+_vm._s(_vm.$t('HeaderGame.round'))+" "+_vm._s(index + 1)+" : "),(_vm.isCountry)?_c('FlagIcon',{attrs:{"iso-name":r.country || r.area || r.position.country || r.position.are}}):_c('span',[_vm._v(_vm._s(r.country || r.area || r.position.country || r.position.area))])],1)]),(_vm.item.multiplayer)?_c('div',_vm._l((r.players),function(value,playerName,index){return _c('div',{key:playerName,staticClass:"result-panel__item",class:{col: !_vm.isCountry}},[(_vm.isCountry)?_c('FlagIcon',{attrs:{"iso-name":value.guess}}):_vm._e(),_c('span',{style:(`color: ${
                            _vm.strokeColors[index % _vm.strokeColors.length]
                        }`)},[_vm._v(_vm._s(playerName))]),(!_vm.isCountry)?_c('em',[_c('br'),_vm._v(_vm._s(value.guess))]):_vm._e()],1)}),0):_c('div',[(_vm.isCountry)?_c('FlagIcon',{attrs:{"iso-name":r.guess}}):_c('em',[_vm._v(_vm._s(r.guess))])],1)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }