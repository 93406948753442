var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"id":"card-settings","disabled":_vm.loadingAreas,"loading":_vm.loadingAreas}},[_c('v-card-title',[_c('p',[_vm._v(_vm._s(_vm.$t('CardRoomSettings.title')))])]),_c('v-card-text',{staticClass:"settings"},[_c('v-row',[_c('v-col',[_c('v-row',[_c('label',{staticClass:"card_settings__mode__label"},[_vm._v(_vm._s(_vm.$t('CardRoomSettings.modeLabel')))]),_c('v-flex',{staticClass:"card_settings__mode__btns d-flex justify-space-around w-100"},[_c('v-btn',{attrs:{"id":"modeClassicBtn","text":_vm.gameSettings.modeSelected !==
                                    _vm.gameMode.CLASSIC,"rounded":"","outlined":""},on:{"click":() =>
                                    _vm.setGameSettings({
                                        modeSelected: _vm.gameMode.CLASSIC,
                                    })}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-map-marker ")]),_c('span',[_vm._v(_vm._s(_vm.$t('modes.classic')))])],1),_c('v-btn',{attrs:{"id":"modeCountryBtn","text":_vm.gameSettings.modeSelected !==
                                    _vm.gameMode.COUNTRY,"rounded":"","outlined":""},on:{"click":() =>
                                    _vm.setGameSettings({
                                        modeSelected: _vm.gameMode.COUNTRY,
                                    })}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-flag ")]),_c('span',[_vm._v(_vm._s(_vm.$t('modes.country')))])],1),(_vm.gameSettings.modeSelected === _vm.gameMode.CUSTOM_AREA)?_c('v-btn',{attrs:{"id":"modeCustomAreaBtn","text":_vm.gameSettings.modeSelected !==
                                    _vm.gameMode.CUSTOM_AREA,"rounded":"","outlined":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-flag-checkered ")]),_c('span',[_vm._v(_vm._s(_vm.$t('modes.custom_area')))])],1):_vm._e()],1)],1),_c('v-row',{staticClass:"mb-0"},[_c('label',{staticClass:"card_settings__time__label"},[_vm._v(_vm._s(_vm.$t('CardRoomTime.title')))]),_c('TimePicker',{attrs:{"value":_vm.gameSettings.time},on:{"input":(time) => _vm.setGameSettings({ time })}})],1),_c('v-row',{staticClass:"d-flex justify-space-around flex-row mb-0 mt-0",attrs:{"align":"center"}},[_c('v-col',[_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.zoomControl,"label":_vm.$t('CardRoomSettings.allowZoom'),"hide-details":""},on:{"change":(zoomControl) =>
                                    _vm.setGameSettings({ zoomControl })}}),_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.moveControl,"label":_vm.$t('CardRoomSettings.allowMove'),"hide-details":""},on:{"change":(moveControl) =>
                                    _vm.setGameSettings({ moveControl })}}),_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.panControl,"label":_vm.$t('CardRoomSettings.allowPan'),"hide-details":""},on:{"change":(panControl) =>
                                    _vm.setGameSettings({ panControl })}}),_c('v-checkbox',{staticClass:"mt-2",attrs:{"input-value":_vm.gameSettings.allPanorama,"label":_vm.$t('CardRoomSettings.includePhotopheres'),"hide-details":""},on:{"change":(allPanorama) =>
                                    _vm.setGameSettings({ allPanorama })}}),_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.optimiseStreetView,"label":_vm.$t('CardRoomSettings.optimiseStreetView'),"hide-details":""},on:{"change":(optimiseStreetView) =>
                                    _vm.setGameSettings({ optimiseStreetView })}}),(!_vm.singlePlayer)?_c('v-checkbox',{staticClass:"mt-2",attrs:{"input-value":_vm.gameSettings.scoreLeaderboard,"label":_vm.$t('CardRoomSettings.scoreLeaderboard'),"hide-details":""},on:{"change":(scoreLeaderboard) =>
                                    _vm.setGameSettings({ scoreLeaderboard })}}):_vm._e(),(!_vm.singlePlayer)?_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.guessedLeaderboard,"label":_vm.$t('CardRoomSettings.guessedLeaderboard'),"hide-details":"","disabled":_vm.gameSettings.scoreLeaderboard},on:{"change":(guessedLeaderboard) =>
                                    _vm.setGameSettings({ guessedLeaderboard })}}):_vm._e()],1),_c('v-col',[(!_vm.singlePlayer)?_c('v-text-field',{attrs:{"label":_vm.$t('CardRoomSettings.countDownLabel'),"value":_vm.gameSettings.countdown,"hide-details":"","type":"number"},on:{"input":(countdown) =>
                                    _vm.setGameSettings({
                                        countdown: +countdown,
                                    })}}):_vm._e(),(
                                _vm.gameSettings.modeSelected !==
                                    _vm.gameMode.CLASSIC && !_vm.singlePlayer
                            )?_c('div',[_c('v-checkbox',{attrs:{"input-value":_vm.gameSettings.timeAttackSelected,"hide-details":""},on:{"change":(timeAttackSelected) =>
                                        _vm.setGameSettings({
                                            timeAttackSelected,
                                        })},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( 'CardRoomSettings.timeAttackLabel' ))+" "),_c('v-tooltip',{staticClass:"tooltip-timeattack",attrs:{"top":"","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}],null,false,591445961)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'CardRoomSettings.timeattackInfos' )))])])]},proxy:true}],null,false,2680940937)})],1):_vm._e()],1)],1),_c('v-row',{staticClass:"mb-0 mt-0",attrs:{"align":"center"}},[_c('v-col',[_c('v-list-group',{attrs:{"prepend-icon":"mdi-cog"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('CardRoomSettings.moreSettings'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"number","disabled":_vm.gameSettings.timeAttackSelected,"label":_vm.$t('CardRoomSettings.nbRound'),"value":_vm.gameSettings.timeAttackSelected ? 10 : _vm.gameSettings.nbRoundSelected,"min":"1"},on:{"input":(nbRoundSelected) =>
                                        _vm.setGameSettings({ nbRoundSelected: +nbRoundSelected })}}),(
                                    _vm.gameSettings.modeSelected ===
                                        _vm.gameMode.CLASSIC
                                )?_c('v-select',{attrs:{"label":_vm.$t('CardRoomSettings.scoreModeLabel'),"input-value":_vm.gameSettings.scoreMode,"items":_vm.scoreModes},on:{"change":(scoreMode) =>
                                        _vm.setGameSettings({ scoreMode })}}):_vm._e(),(_vm.optionsArea.length > 0)?_c('v-autocomplete',{attrs:{"label":_vm.$t('CardRoomSettings.selectAreas'),"value":_vm.gameSettings.areaParams,"items":_vm.optionsArea},on:{"input":(areaParams) =>
                                        _vm.setGameSettings({ areaParams })}}):_vm._e()],1)],1),_c('v-col')],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"dark":"","depressed":"","color":"error"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"id":"btnNextSettings","dark":"","depressed":"","color":"#43B581"},on:{"click":_vm.onClickNext}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }